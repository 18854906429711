import React, { useEffect, useState } from 'react';
import { Wrapper, Content, BanerImg } from '../Baner/Baner.styles';

import baner1 from '../../images/banery/baner-top_super_2024.jpg'
import baner2 from '../../images/banery/baner-top_dodatki_2_2024.jpg'
import baner3 from '../../images/banery/baner-top_feeder_2024.jpg'
import baner4 from '../../images/banery/baner-top_kolowrotek_2024.jpg'
import baner5 from '../../images/banery/baner-top_splawiki_2024.jpg'

import {Link} from 'react-router-dom';
const baners = [baner1, baner2, baner3, baner4, baner5]
const banerLinks = ['/Products/16', '/Products/17', '/Products/27', '/Product/228', '/Products/69']
const delay = 6000 // miliseconds


const Baner = () => {
    const [banerId, setBanerId] = useState(0)    
    useEffect( () => 
        setTimeout(() => 
            setBanerId(banerId+1 < baners.length ? banerId+1 : 0)
        , delay)
    )

    return (
        <Wrapper>
            <Link to={banerLinks[banerId]} style={{ textDecoration: 'none' }}>
                <Content>
                    <BanerImg src={baners[banerId]} alt='baner-image'></BanerImg>
                </Content>
            </Link>
        </Wrapper>
    )
}

export default Baner;