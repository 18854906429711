import React, { Component } from 'react';
import { Wrapper, Content, MainContent, Text, TextLink, Textbar, Border, ListElement,
     Topbar, Pathbar, Textbarwhite, CategoryList, NewsSheetWrapper, NewsSheetContainer, NewsSheet,
     Square, Textsquare, SubCategories, CategoryImg, Grid, Icon, ProductText,
     Product, HorLine, CategoryText } from './Category.styles';
import Arrow from '../../images/arrow_right_blue.png';
import {Link} from 'react-router-dom';
import Loading from '../Loading'

import img1 from '../../images/catalog/zanety.png';
import img2 from '../../images/catalog/wedziska.png';
import img3 from '../../images/catalog/kolowrotki.png';
import Lightbox from 'react-image-lightbox';

let imgToShow = {
    16: img1,
    22: img2,
    28: img3,
}

const url='https://lorpio-back.ang3r.pl/api/';


class Categorypage extends Component{
    constructor(props){
        super(props)
        this.state={    
            isModalOpen: false,
            isLoading: true,        
            current_id: null,           // current category id
            parentCategoryId: null,     // parent category id
            categories: [],             // all categories
            current_category: null,     // current category name
            current_category_catalog_page: null,     // current category catalogi page
            current_category_lvl: 0,    // current category lvl
            current_branch_route: [],   // route from current category to 0 lvl category, if current is 0 lvl then []
            // products: [],               // category products (top level)
            subCategories: [],          // current category's sub categories
            subCategoriesTitles: [],    // current category's sub categories titles
            newProducts: [],            // new products from all categories
            currentCategoryNewProducts: [],
            category_new_products_empty: true,
            unified_category_list: []
        }        
    }
    async componentDidMount(){
        const response = await fetch(url + 'categories');
        const data = await response.json();                
        this.setState({ current_id: parseInt(window.location.href.split('/')[4]) }); 
        this.setState({ categories: data.zeroLevelCategories.map( cat => ({ ...cat, depth: 0 }) ) });

        const response2 = await fetch(url + 'categories/' + this.state.current_id);
        const data2 = await response2.json();
        this.setState({
            parentCategoryId: data2.parent_category_id,
            subCategories: data2.subcategories,
            subCategoriesTitles: data2.subcategories.map(item => item.title)
        })

        let parentNode = this.state.parentCategoryId;
        let branchRoute = []
        let currentLvl = 0

        for (let i=0; i<10; i++) {
            if (parentNode === null) {
                if (currentLvl === 0) {
                    this.state.categories.forEach(category => {            
                        if(category.id === this.state.current_id) {                
                            this.setState({ current_category: category.title, current_category_catalog_page: category.catalogPage });
                        }            
                    });
                }
                break;
            } else {
                const response_x = await fetch(url + 'categories/' + parentNode);
                const data_x = await response_x.json();
                let subCategories = data_x.subcategories
                if (currentLvl === 0) {
                    subCategories.forEach( sub => {
                        if (sub.id === this.state.current_id) {
                            this.setState({ current_category: sub.title, current_category_catalog_page: sub.catalogPage })
                        }
                    })
                }
                branchRoute.unshift( [ parentNode, subCategories ] );
                parentNode = data_x.parent_category_id;
                currentLvl++;
            }
        }

        this.setState({
            current_branch_route: branchRoute,
            current_category_lvl: currentLvl,
        })

        const response3 = await fetch( url + 'new_products' );
        const data3 = await response3.json();
        this.setState({ newProducts: data3.new_products });

        let nestedSubCategoriesIds = [this.state.current_id]
        let toBeCheckedIds = this.state.subCategories.map( sub => sub.id )

        while(toBeCheckedIds.length) {
            let subId = toBeCheckedIds.pop()
            nestedSubCategoriesIds.push(subId)
            const response_x = await fetch(url + 'categories/' + subId);
            const data_x = await response_x.json();
            if (data_x.subcategories.length) {
                toBeCheckedIds.push(...data_x.subcategories.map( sub => sub.id ))
            }
        }

        this.setState({
            currentCategoryNewProducts: this.state.newProducts.filter(product => nestedSubCategoriesIds.includes(product.category_id) )
        })

        this.setState({
            category_new_products_empty: this.state.currentCategoryNewProducts.length ? false : true
        })

        this.setState({
            unified_category_list: this.state.categories
        })

        this.state.current_branch_route.forEach( ([id, subs], index) => {
            this.setState({
                unified_category_list: this.state.unified_category_list.map( cat => {
                    if (cat.id === id) {
                        return [cat, subs.map(sub => ({ ...sub, depth: index+1 }))]
                    } else {
                        return cat
                    }
                }).flat(2)
            })
        })

        this.setState({
            category: this.state.unified_category_list
        })

        this.setState({isLoading: false})
        // this all should not be in separate setState() - fix will fix loading twitching

    }

    render(){
        return(
            (this.state.isLoading)
            ?
                <Loading />
            :
                <Wrapper>
                    <Topbar>
                        <Textbar>PRODUKTY</Textbar>
                        <Textbarwhite>{this.state.current_category}</Textbarwhite>
                    </Topbar>

                    <Pathbar>
                        <Link to="/" style={{ textDecoration: 'none' }}>
                            <TextLink>HOME</TextLink>
                        </Link>
                        <img src={Arrow} alt='arrow'/>
                        <Link to="/Products" style={{ textDecoration: 'none' }}>
                            <TextLink>PRODUKTY</TextLink>
                        </Link>
                        <img src={Arrow} alt='arrow'/>
                        <Text>{this.state.current_category}</Text>
                    </Pathbar>

                    <Content>
                        <CategoryList>
                            {this.state.unified_category_list.map((item)=>{
                                if(item.title === 'NOWOŚCI'){
                                    return(
                                        <Link to={`/News`} key={item.title} onClick={this.forceUpdate} 
                                        style={{ textDecoration: 'none'}}>
                                            <ListElement style={{ fontWeight:'800' }}>{item.title}</ListElement>
                                        </Link>
                                    )
                                }
                                else{
                                    
                                    return(
                                        <div key={item.title} style={{paddingLeft: `${item.depth*20}px`}}>
                                            <Link to={`/Products/${item.id}`} onClick={this.forceUpdate} 
                                            style={{ textDecoration: 'none'}}>
                                                <ListElement style={{ fontWeight:'800' }}>{item.title}</ListElement>
                                            </Link>
                                        </div>
                                    )
                                }                        
                            })
                            }                        
                        </CategoryList>

                        <MainContent>

                            <NewsSheetWrapper>
    {/*                                <iframe src={"https://publuu.com/flip-book/12525/179376/page/" + this.state.current_category_catalog_page + "?embed"} scrolling="no"*/}
    {/*allowFullScreen="" className="publuuflip" width="100%" height="500"*/}
    {/*frameBorder="0"/>*/}
    {/*                                <script async defer*/}
    {/*src='https://publuu.com/flip-book/assets/scripts/embed2911.js'/>*/}

                                <a href={"https://publuu.com/flip-book/12525/631712/page/" + this.state.current_category_catalog_page + "?cover"}
                                   style={{width:'auto', height: '350px', position: 'relative', display: 'inline-block'}}
                                   target="_blank" rel="noopener"
                                   className="show-publuu-viewer"><img
                                    src={"https://d1uiew9hysv4w7.cloudfront.net/12525/631712/2/txt/631712_" + this.state.current_category_catalog_page + "_1200.webp"}
                                    // src={"https://g2.publuu.com/cover.php?iid=631712&pid=12525&page=" + this.state.current_category_catalog_page + "&time=1641804289"}
                                    style={{height: '100%'}} /></a>

                            </NewsSheetWrapper>


                            <SubCategories>                            
                                {this.state.subCategories.map((item) => (     
                                    <Link to={`/Products/${item.id}`} key={item.title} onClick={this.forceUpdate} style={{ textDecoration: 'none' }}>
                                        <Square >
                                            <Textsquare >{item.title}</Textsquare>
                                        </Square>                   
                                    </Link> 
                                ))}
                            </SubCategories>

                            {!this.state.category_new_products_empty && (
                                <HorLine/>
                            )}

                            {!this.state.category_new_products_empty && (
                            
                            <CategoryImg>
                                <CategoryText>NOWOŚCI</CategoryText>
                                <Grid>
                                    {this.state.currentCategoryNewProducts.map((item)=>(
                                        <Link to={`/Product/${item.product_id}`} key={item.title} 
                                            style={{ textDecoration: 'none' }}>
                                            <Product key={item.title}>
                                                <Border>
                                                    <Icon src={'https://lorpio-back.ang3r.pl'+item.images[0]} alt='product_icon' ></Icon>
                                                </Border>                                        
                                                <ProductText>{item.title}</ProductText>
                                            </Product>    
                                        </Link>             
                                    ))}
                                </Grid>
                            </CategoryImg>
                            )}
                        </MainContent>
                        
                    </Content>
                </Wrapper>
        )
    }
}
export default Categorypage;
